import { Scrambler } from 'components'
import { toKeys, map } from 'utils'
import { React, styled } from 'x'
import { useState, useCallback } from 'react'

import {
  Doin,
  Flow,
  Patch,
  Three,
  Points,
  Aurora,
  Volume,
  Minimal,
  Voronoi,
  SplitText,
  Particles,
  SlidingDoor,
 } from 'demos'

const demos = {
  Doin,
  Flow,
  Patch,
  Three,
  Points,
  Aurora,
  Volume,
  Minimal,
  Voronoi,
  SplitText,
  Particles,
  SlidingDoor,
}

const Wrapper = styled.div`
  padding-top: 150px;
`

const Demo = props => {
  const [demo, setDemo] = useState('Patch')

  const Component = demos[demo]

  return (
    <Wrapper>
      {
        map(key => (
          <button key={ key } onClick={ () => setDemo(key) }>{ key }</button>
        ), toKeys(demos))
      }
      <Component />
    </Wrapper>
  )
}

export default Demo
