import { React, styled } from 'x'
import { useRef, useState, useEffect } from 'react'
import { pow, sin, cos, flatten, assign } from 'utils'
import { Regl, Camera, Controls, resl, glsl } from 'gl'

const WIDTH = 900
const HEIGHT = 900
const POINTS = 5

const factorial = (n) => {
  let result = 1

  for (let i = n; i > 1; i--) {
    result *= i
  }

  return result
}

const binomialCoefficient = (i, order) => {
  return factorial(order) / (factorial(i) * factorial(order - i))
}

const bernsteinPolynomial = (i, u, order = 3) => {
  return binomialCoefficient(i, order) * pow(u, i) * pow(1 - u, order - i)
}

const bernstein = (t) => {
	return [
		((1.0 - t) * (1.0 - t) * (1.0 - t)),
		3.0 * ((1.0 - t) * (1.0 - t)) * t,
		3.0 * (1.0 - t) * (t * t),
		(t * t * t),
  ]
}

const bernsteinDerivative = (t) => {
  return [
    -3.0 * ((1.0 - t) * (1.0 - t)),
    3.0 * ((1.0 - t) * (1.0 - t)) - 6.0 * t * (1.0 - t),
    6.0 * t * (1.0 - t) - 3.0 * (t * t),
    3.0 * (t * t),
  ]
}

const createGrid = (length = 10) => {
  const uvs = Array.from({ length }, (_, i) => (
    Array.from({ length }, (_, j) => [
      i / (length - 1),
      j / (length - 1),
    ])
  ))

  return flatten(uvs)
}

const createIndices = (length = 10) => {
  let indices = [];
	let currentIndex = 0;
	let endIndex = length;
  let j, i
	for (j = 0; j < length - 1; j++) {
		if (j !== 0) {
			indices.push(currentIndex + endIndex - 1);
			indices.push(currentIndex);
		}

		for (i = 0; i < length; i++) {
			indices.push(currentIndex);
			indices.push(currentIndex + endIndex);
			currentIndex++;
		}
	}
  return indices
}

const Wrapper = styled.div`
  width: ${ props => props.width }px;
  height: ${ props => props.height }px;
`

const setup = ({
  x,
  regl,
  width,
  height,
  canvas,
  background,
}) => {
  const grid = createGrid(POINTS)
  const indices = createIndices(POINTS)
  const startTime = Date.now()

  console.log('indices:', indices)

  const controlPoints = {
    x: [
      -1, -.33, .33, 1,
      -1, -.33, .33, 1,
      -1, -.33, .33, 1,
      -1, -.33, .33, 1,
    ],
    y: [
      0, 0, 0, 0,
      0, 0, 0, 1,
      0, 0, 0, 0,
      0, 0, 0, 0,
    ],
    z: [
      -1, -1, -1, -1,
      -.33, -.33, -.33, -.33,
      .33, .33, .33, .33,
      1, 1, 1, 1,
    ],
  }

  const camera  = Camera({
    fov: 50 * Math.PI / 180,
    position: [0, 1, 1],
    near: 0.00001,
    far: 100,
    viewport: [0, 0, width, height]
  })

  const controls = Controls({
    position: camera.position,
    element: canvas,
    distanceBounds: [1, 100],
    distance: 3,
  })

  const elements = regl.elements({
    primitive: 'line strip',
    count: indices.length,
    data: new Uint8Array(indices),
  })

  const draw = regl(new class{
    // count = grid.length
    dither = true
    primitive = 'line strip'
    elements = elements
    // count = grid.length
    // frontFace = 'ccw'
    // primitive = 'triangle strip'

    blend = {
      enable: true,
      func: {
        srcRGB: 'one',
        srcAlpha: 'one',
        dstRGB: 'one minus src alpha',
        dstAlpha: 'one minus src alpha',
      },
    }

    attributes = {
      uv: grid,
    }

    uniforms = {
      x: regl.texture(x, {
        min: 'nearest',
        mag: 'nearest',
      }),
      time: regl.prop('time'),
      view: regl.prop('view'),
      projection: regl.prop('projection'),
      resolution: regl.prop('resolution'),
      cpX: regl.prop('cpX'),
      cpY: regl.prop('cpY'),
      cpZ: regl.prop('cpZ'),
    }

    vert = glsl`
      precision highp float;

      uniform mat4 projection;
      uniform mat4 view;
      uniform mat4 cpX;
      uniform mat4 cpY;
      uniform mat4 cpZ;

      attribute vec2 uv;

      varying vec2 vUv;

      vec4 bernstein(float t) {
        return vec4(
          ((1.0 - t) * (1.0 - t) * (1.0 - t)),
          3.0 * ((1.0 - t) * (1.0 - t)) * t,
          3.0 * (1.0 - t) * (t * t),
          (t * t * t)
        );
      }

      vec4 bernsteinDerivative(float t) {
  			return vec4(
  				-3.0 * ((1.0 - t) * (1.0 - t)),
  				3.0 * ((1.0 - t) * (1.0 - t)) - 6.0 * t * (1.0 - t),
  				6.0 * t * (1.0 - t) - 3.0 * (t * t),
  				3.0 * (t*t)
  			);
  		}

      vec4 bernsteinSecondDerivative(float t) {
        return vec4(
          6.0 - 6.0 * t,
          18.0 * t - 12.0,
          6.0 - 18.0 * t,
          6.0 * t
        );
      }

      void main() {
        vec4 bU = bernstein(uv.x);
        vec4 bV = bernstein(uv.y);
        vec4 bdU = bernsteinDerivative(uv.x);
        vec4 bdV = bernsteinDerivative(uv.y);

        vec3 position = vec3(
          dot((bU * cpX), bV),
          dot((bU * cpY), bV),
          dot((bU * cpZ), bV)
        );

        vec3 tangentU = normalize(vec3(
          dot((bdU * cpX), bV),
          dot((bdU * cpY), bV),
          dot((bdU * cpZ), bV)
        ));

        vec3 tangentV = normalize(vec3(
          dot((bU * cpX), bdV),
          dot((bU * cpY), bdV),
          dot((bU * cpZ), bdV)
        ));

        vec3 normal = cross(tangentV, tangentU);

        vUv = vec2(position.xy);

        gl_Position = projection * view * vec4(position, 1.0);
        // gl_PointSize = 7.;
      }
    `

    frag = glsl`
      precision highp float;

      uniform mat4 resolution;
      uniform float time;
      uniform sampler2D x;

      varying vec2 vUv;

      void main() {
        // vec2 x = abs(gl_PointCoord - vec2(.5));
        // gl_FragColor = vec4(x, .7, 1.);
        // gl_FragColor = vec4(vUv, .7, 1.);
        // gl_FragColor = vec4(vec3(texture2D(x, gl_PointCoord)), .2) * vec4(vUv, 1.0, 1.0);
        // gl_FragColor = vec4(vec3(texture2D(x, gl_PointCoord)), .2);
        // gl_FragColor.rgb *= gl_FragColor.a;

        gl_FragColor = vec4(vUv, 0.7, 1.0);
      }
    `
  })

  regl.frame(({
    tick,
    time,
    pixelRatio,
    viewportWidth,
    viewportHeight,
  }) => {
    regl.clear({ color: background })

    controls.update()
    controls.copyInto(camera.position, camera.direction, camera.up)
    camera.update()

    const t = 1 || (time / 2) % 1

    controlPoints.y[0] = cos(2 * Math.PI * t) / 2
    controlPoints.y[3] = sin(2 * Math.PI * t) / 4
    controlPoints.y[12] = sin(2 * Math.PI * t) / 6
    controlPoints.y[15] = cos(2 * Math.PI * t) / 4

    controlPoints.y[1] = sin(2 * Math.PI * t)
    controlPoints.y[2] = sin(-4 * Math.PI * t)
    controlPoints.y[5] = cos(2 * Math.PI * t)
    controlPoints.y[13] = sin(-4 * Math.PI * t)
    controlPoints.y[14] = sin(2 * Math.PI * t)

    draw({
      view: camera.view,
      projection: camera.projection,
      resolution: [viewportWidth, viewportHeight],
      cpX: controlPoints.x,
      cpY: controlPoints.y,
      cpZ: controlPoints.z,
    })
  })
}

const Patch = ({
  background = [0, 0, 0, 1],
  width = WIDTH,
  height = HEIGHT,
  points = POINTS,
}) => {
  const ref = useRef(null)
  const scroll = useRef(null)

  // const [{ width, height }, setSize] = useState({
  //   width: window.innerWidth,
  //   height: window.innerHeight,
  // })

  useEffect(() => {
    if (!ref.current) {
      return
    }

    const state = {
      regl: null,
    }

    const canvas = ref.current

    resl({
      manifest: {
        x: {
          type: 'image',
          src: '/img/x.png',
        },
      },

      onError: error => {
        console.log('ERROR', error)
      },

      onDone: ({ x }) => {
        const { regl } = assign(state, {
          regl: Regl({
            canvas,
            extensions: [
              'OES_texture_float',
              'OES_texture_float_linear',
            ]
          })
        })

        setup({
          regl,
          x,
          width,
          height,
          points,
          canvas,
          background,
        })
      }
    })

    return () => state.regl ? state.regl.destroy() : null
  }, [ref.current])

  return (
    <Wrapper
      width={ width }
      height={ height }
    >
      <canvas
        ref={ ref }
        width={ width }
        height={ height }
        style={{
          display: 'block',
        }}
      />
    </Wrapper>
  )
}

export default Patch
