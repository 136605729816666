import { THREE, glsl } from 'gl'
import { React, styled } from 'x'
import { useRef, useState, useEffect } from 'react'

const Wrapper = styled.div`
  width: ${ props => props.width }px;
  height: ${ props => props.height }px;
`

const loadTexture = url => new Promise(resolve => {
  new THREE.TextureLoader().load(url, resolve)
})

const setup = async ({
  root,
  width,
  height,
}) => {

  const scene = new THREE.Scene()
  const camera = new THREE.PerspectiveCamera(75, width / height, 0.1, 1000)
  const renderer = new THREE.WebGLRenderer()

  renderer.setSize(width, height)
  root.appendChild(renderer.domElement)

  const geometries = {
    box: new THREE.BoxGeometry(1, 1, 1),
    sphere: new THREE.SphereBufferGeometry(1, 25, 25)
  }

  const material = new THREE.MeshBasicMaterial({ color: 0x0077ff })
  // const cube = new THREE.Mesh(geometries.box, material)
  const sphere = new THREE.Mesh(geometries.sphere, material)

  // scene.add(cube)
  scene.add(sphere)

  camera.position.z = 5

  const animate = () => {
    requestAnimationFrame(animate)

    // cube.rotation.x += 0.01
    // cube.rotation.y += 0.01

    renderer.render(scene, camera)
  }

  animate()
}

const Three = props => {
  const ref = useRef(null)

  const [{ width, height }, setSize] = useState({ width: 900, height: 900 })

  useEffect(() => {
    if (!ref.current) {
      return
    }

    setup({
      root: ref.current,
      width: width,
      height: height,
    })
  })

  return (
    <Wrapper ref={ ref } />
  )
}

export default Three
