import { onMount } from 'hooks'
import { React, styled } from 'x'
import { useState, useEffect, useCallback } from 'hooks'

const add  = (x, y) => x + y

const addOne = add(1, ?)
const addTen = add(?, 10)

/*
console.log('addOne', addOne(2))
console.log('addTen', addTen(2))
*/

// —————————————————————————————————————————————————————————————————————————————

const Articles = do {
  const Title = styled.h1`
    color: rebeccapurple;
  `

  const Excerpt = styled.p`
    color: #0c0c0c;
  `

  const Article = styled.div`

  `

  const Wrapper = styled.div`
    padding: 10px;
    border: 1px sollid #fafafa;
    background: #fafafa;

    ${ Article }:not(:last-child) {
      margin-bottom: 10px;
    }
  `

  const Container = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 20px;
    border 1px solid #000;
  `

  {
    props => (
      <Wrapper>
        <Container>
          {
            props.articles.map(({ title, excerpt }, index)  => (
              <Article key={ index }>
                <Title>{ title }</Title>
                <Excerpt>{ excerpt }</Excerpt>
              </Article>
            ))
          }
        </Container>
      </Wrapper>
    )
  }
}

// —————————————————————————————————————————————————————————————————————————————

const Todos = do {
  const Title = styled.h1`
    color: rebeccapurple;
  `

  const Wrapper = styled.div`
    padding: 10px;
    border: 1px sollid #fafafa;
    background: #fafafa;

    ${ Title }:not(:last-child) {
      margin-bottom: 10px;
    }
  `

  const Todo = props => {
    const [a, setA] = useState(0)
    const [b, setB] = useState(1)
    const [c, setC] = useState(2)

    onMount((a, b, c) => {
      console.log('deps', a, b, c)
    }, a, b, c)

    return (
      <div>
        <p>a: { a }</p>
        <p>b: { b }</p>
        <p>c: { c }</p>
      </div>
    )
  }

  {
    ({ todos }) => todos.map((todo, index) => (
      <Todo key={ index } { ...todo } />
    ))
  }
}


// —————————————————————————————————————————————————————————————————————————————

/*
console.log('arrow:', do {
  { props => {} }
})

console.log('function:', do {
  {(
    function Do(props) {}
  )}
})
*/

// —————————————————————————————————————————————————————————————————————————————
export default () => (
  <>
    <Todos
      todos={[
        {
          title: 'A'
        },
        {
          title: 'B'
        },
        {
          title: 'C'
        },
      ]}
    />
    <Articles
      articles={[
        {
            title: 'A',
            'excerpt': 'Excerpt A',
        },
        {
            title: 'B',
            'excerpt': 'Excerpt B',
        },
        {
            title: 'C',
            'excerpt': 'Excerpt C',
        },
        {
            title: 'D',
            'excerpt': 'Excerpt D',
        },
      ]}
    />
  </>
)
