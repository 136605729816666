import { glsl } from 'library'
import { React, styled } from 'x'
import { default as Regl } from 'regl'
import { default as resl } from 'resl'
import { useRef, useEffect } from 'react'
import { default as Camera } from 'perspective-camera'
import { default as Controls } from 'orbit-controls'

const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
`

const createDraw = (regl, {
  a = 3
}) => regl( new class{
  count = 4
  dither = true
  primitive = 'point'
  attributes = {
    position: [
      [ 1, -1],
      [ 1,  1],
      [-1, -1],
      [-1,  1],
    ],
  }

  uniforms = {
    time: regl.prop('time'),
    view: regl.prop('view'),
    projection: regl.prop('projection'),
    resolution: regl.prop('resolution'),
  }

  vert = glsl`
    precision highp float;

    uniform mat4 projection;
    uniform mat4 view;

    attribute vec2 position;

    varying vec2 uv;

    void main() {
      uv = (vec2(1.0) + position) / 2.0;

      gl_Position = projection * view * vec4(position, 0.0, 1.0);
      gl_PointSize = 25.;
    }
  `

  frag = glsl`
    precision highp float;

    uniform mat4 resolution;
    uniform float time;

    void main() {
      gl_FragColor = vec4(0.1, 0.3, .7, 1.);
    }
  `
})

const Volume = props => {
  const ref = useRef(null)

  useEffect(() => {
    if (!ref.current) {
      return
    }

    const regl = Regl({ container: ref.current })

    const camera  = Camera({
      fov: 50 * Math.PI / 180,
      position: [0, 0, 1],
      near: 0.00001,
      far: 100,
    })

    const controls = Controls({
      position: camera.position,
      element: ref.current,
      distanceBounds: [1, 100],
      distance: 1.5,
    })

    resl({
      manifest: {
      },

      onError: error => {
        console.log('ERROR', error)
      },

      onDone: assets => {
        const startTime = Date.now()

        const draw = createDraw(regl, {
          a: 1
        })

        regl.frame(() => {
          const width = window.innerWidth
          const height = window.innerHeight

          controls.update()
          controls.copyInto(camera.position, camera.direction, camera.up)

          camera.viewport = [0, 0, width, height]
          camera.update()

          regl.clear({color: [0, 0, 0, 1]})

          draw({
            time: (Date.now() - startTime) / 1000,
            view: camera.view,
            projection: camera.projection,
            resolution: [width, height],
          })
        })
      }
    })
  })

  return (
    <Wrapper ref={ ref } />
  )
}

export default Volume
