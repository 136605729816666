import { glsl } from 'library'
import { React, styled } from 'x'
import { default as Regl } from 'regl'
import { default as resl } from 'resl'
import { default as Camera } from 'perspective-camera'
import { default as Controls } from 'orbit-controls'
import { useRef, useState, useEffect } from 'react'

const Wrapper = styled.div`
  width: ${ props => props.width }px;
  height: ${ props => props.height }px;
`

const createData = (regl, {
  size = 128,
}) => {
  const numParticles = size * size

  const positions = {
    prev: new Float32Array(numParticles * 4),
    curr: new Float32Array(numParticles * 4),
    next: new Float32Array(numParticles * 4),
  }

  for (let i = 0; i < numParticles; ++i) {
    const x = 2 * Math.random() - 1
    const y = 2 * Math.random() - 1
    const z = 2 * Math.random() - 1

  	positions.prev[i * 4] = x
  	positions.prev[i * 4 + 1] = y
  	positions.prev[i * 4 + 2] = z

    positions.curr[i * 4] = (2 * Math.random() - 1) * .7 + x
    positions.curr[i * 4 + 1] = (2 * Math.random() - 1) * .7 + y
    positions.curr[i * 4 + 2] = (2 * Math.random() - 1) * .7 + z

  	positions.next[i * 4] = x
  	positions.next[i * 4 + 1] = y
  	positions.next[i * 4 + 2] = z
  }

  const textures = {
    prev: regl.texture({
      data: positions.prev,
      shape: [size, size, 4],
      colorType: 'float',
      depthStencil: false,
    }),

    curr: regl.texture({
      data: positions.curr,
      shape: [size, size, 4],
      colorType: 'float',
      depthStencil: false,
    }),

    next: regl.texture({
      data: positions.next,
      shape: [size, size, 4],
      colorType: 'float',
      depthStencil: false,
    }),
  }

  const framebuffers = {
    prev: regl.framebuffer({
      color: textures.prev,
      depth: false,
      stencil: false,
    }),

    curr: regl.framebuffer({
      color: textures.curr,
      depth: false,
      stencil: false,
    }),

    next: regl.framebuffer({
      color: textures.next,
      depth: false,
      stencil: false,
    })
  }

  // const indices = Array.from({ length: numParticles}, (v, i) => {
  //   return [(i % size) / size, (Math.floor(i / size)) / size]
  // })

  const indices = [];
  for (let i = 0; i < size; i++) {
  	for (let j = 0; j < size; j++) {
  		indices.push([i / size, j / size]);
  	}
  }

  return {
    indices,
    positions,
    textures,
    framebuffers,
  }
}

const createShaders = (regl, {
  data
}) => {
  const cycleParticles = () => {
    const temp = data.framebuffers.prev

    data.framebuffers.prev = data.framebuffers.curr
    data.framebuffers.curr = data.framebuffers.next
    data.framebuffers.next = temp
  }

  const updateParticles = regl(new class {
    framebuffer = props => data.framebuffers.next


    depth = {
      enable: false
    }

    uniforms = {
      prevPositions: () => data.framebuffers.prev,
      currPositions: () => data.framebuffers.curr,
    }

    /*
    count = 4

    attributes = {
      position: [
        [ 1, -1],
        [ 1,  1],
        [-1, -1],
        [-1,  1],
      ],
    }

    vert = glsl`
      precision mediump float;

      attribute vec2 position;

      varying vec2 uv;

      void main() {
        uv = 0.5 * (1.0 + position);

        gl_Position = vec4(position, 0, 1);
      }
    `
    */
    count = 3

    attributes = {
      position: [
        -4, 0,
        4, 4,
        4, -4
      ]
    }

    vert = glsl`
      precision mediump float;
      attribute vec2 position;

      varying vec2 uv;

      void main() {
        uv = 0.5 * (1.0 + position);

        gl_Position = vec4(position, 0, 1);
      }
    `

    frag = glsl`
      precision highp float;

      uniform sampler2D currPositions;
      uniform sampler2D prevPositions;

      varying vec2 uv;

      float rand(vec2 seed){
        return fract(sin(dot(seed.xy, vec2(12.9898, 78.233))) * 43758.5453);
      }

      void main() {
        vec3 currPosition = texture2D(currPositions, uv).xyz;
        vec3 prevPosition = texture2D(prevPositions, uv).xyz;

        vec3 velocity = currPosition - prevPosition;
        // vec3 random = 0.5 - vec2(rand(currPosition), rand(10.0 * currPosition));

        vec3 position = currPosition + (0.95 * velocity);

        gl_FragColor = vec4(position, 1.);
      }
    `
  })

  const drawParticles = regl(new class {
    count = data.indices.length
    dither = true
    primitive = 'point'

    depth = {
      enable: false,
      mask: false,
    }

    attributes = {
      index: data.indices,
    }

    uniforms = {
      time: regl.prop('time'),
      view: regl.prop('view'),
      projection: regl.prop('projection'),
      resolution: regl.prop('resolution'),
      currPositions: () => data.framebuffers.curr,
    }

    vert = glsl`
      precision mediump float;

      uniform mat4 projection;
      uniform mat4 view;
      uniform sampler2D currPositions;

      attribute vec2 index;

      varying vec2 uv;

      void main() {
        // uv = (vec2(1.0) + position) / 2.0;
        vec4 position = texture2D(currPositions, index);

        gl_Position = projection * view * vec4(position.xyz, 1.0);
        gl_PointSize = 5.;
      }
    `

    frag = glsl`
      precision highp float;

      uniform mat4 resolution;
      uniform float time;

      void main() {
        gl_FragColor = vec4(0.1, 0.3, .7, 1.);
      }
    `
  })

  return {
    drawParticles,
    updateParticles,
    cycleParticles,
  }
}

const Particles = props => {
  const ref = useRef(null)
  const [{ width, height }, setSize] = useState({ width: 100, height: 100 })

  useEffect(() => {
    if (!ref.current || width !== 100) {
      return
    }

    const regl = Regl({
      canvas: ref.current,
      extensions: [
        'OES_texture_float',
        'OES_texture_float_linear',
      ]
    })

    setSize({
      width: window.innerHeight,
      height: window.innerHeight,
    })

    const camera  = Camera({
      fov: 50 * Math.PI / 180,
      position: [0, 0, 10],
      near: 0.00001,
      far: 100,
      viewport: [0, 0, window.innerHeight, window.innerHeight]
    })

    const controls = Controls({
      position: camera.position,
      element: ref.current,
      distanceBounds: [1, 100],
      distance: 30,
    })

    resl({
      manifest: {
      },

      onError: error => {
        console.log('ERROR', error)
      },

      onDone: assets => {
        const startTime = Date.now()

        const data = createData(regl, {
          size: 64
        })

        const {
          drawParticles,
          updateParticles,
          cycleParticles,
        } = createShaders(regl, {
          data
        })

        regl.frame(() => {
          controls.update()
          controls.copyInto(camera.position, camera.direction, camera.up)

          camera.viewport = [0, 0, width, height]
          camera.update()

          updateParticles()

          regl.clear({ color: [0, 0, 0, 1] })

          drawParticles({
            time: (Date.now() - startTime) / 1000,
            view: camera.view,
            projection: camera.projection,
            resolution: [width, height],
          })

          cycleParticles()
        })
      }
    })
  }, [ref])

  return (
    <Wrapper
      width={ width }
      height={ height }
    >
      <canvas
        ref={ ref }
        width={ width }
        height={ height }
      />
    </Wrapper>
  )
}

export default Particles
