import { React, styled } from 'x'
import { default as anime } from 'animejs'
import { useRef, useState, useEffect } from 'react'
import { Regl, Camera, Controls, resl, glsl } from 'gl'

const Wrapper = styled.div`
  width: ${ props => props.width }px;
  height: ${ props => props.height }px;
`

const setupResources = (regl, {
  size = 128,
}) => {
  const numParticles = size * size

  const createFramebuffer = (data) => (
    regl.framebuffer({
      depth: false,
      stencil: false,
      color: regl.texture({
        data: data,
        shape: [size, size, 4],
        type: 'float',
        min: 'nearest',
        mag: 'nearest',
      }),
    })
  )

  const startData = Array.from({ length: numParticles }, (v, i) => ([
    0,
    0,
    0,
    1,
  ]))

  const targetData = Array.from({ length: numParticles }, (v, i) => ([
    Math.random() * 2 - 1,
    Math.random() * 2 - 1,
    Math.random() * 2 - 1,
    1,
  ]))

  const colorData = Array.from({ length: numParticles }, (v, i) => ([
    Math.random(),
    Math.random(),
    Math.random(),
    1,
  ]))

  const velocityData = Array.from({ length: numParticles }, (v, i) => ([
    0,
    0,
    0,
    1,
  ]))

  const framebuffers = {
    start: createFramebuffer(startData),
    target: createFramebuffer(targetData),
    current: createFramebuffer(startData),
    velocities: createFramebuffer(startData),
    colors: createFramebuffer(colorData),
  }

  const uvs = Array.from({ length: numParticles }, (_, i) => ([
    (i % size) / (size - 1),
    Math.floor(i / size) / (size - 1),
  ]))

  return {
    uvs,
    framebuffers,
  }
}

const createShaders = (regl, {
  uvs,
  framebuffers,
}) => {
  const updateParticles = regl(new class {
    framebuffer = props => framebuffers.current

    depth = {
      enable: false
    }

    uniforms = {
      t: regl.prop('t'),
      amplitude: regl.prop('amplitude'),
      startPositions: () => framebuffers.start,
      targetPositions: () => framebuffers.target,
      velocities: () => framebuffers.velocities,
    }

    count = 3

    attributes = {
      position: [
        -4, 0,
        4, 4,
        4, -4
      ]
    }

    vert = glsl`
      precision mediump float;
      attribute vec2 position;

      varying vec2 uv;

      void main() {
        uv = 0.5 * (1.0 + position);

        gl_Position = vec4(position, 0, 1);
      }
    `

    frag = glsl`
      precision highp float;

      uniform float t;
      uniform float amplitude;
      uniform sampler2D startPositions;
      uniform sampler2D targetPositions;
      uniform sampler2D velocities;

      varying vec2 uv;

      void main() {
        vec3 startPosition = texture2D(startPositions, uv).xyz;
        vec3 targetPosition = texture2D(targetPositions, uv).xyz;

        vec3 velocity = texture2D(startPositions, uv).xyz;

        vec3 position = (targetPosition - startPosition) * amplitude;
        // vec3 position = mix(startPosition, targetPosition, t);
        // vec3 position = currPosition + (0.95 * velocity);

        gl_FragColor = vec4(position, 1.);
      }
    `
  })

  const drawParticles = regl(new class {
    count = uvs.length
    dither = true
    primitive = 'point'

    depth = {
      enable: false,
      mask: false,
    }

    attributes = {
      uv: uvs,
    }

    uniforms = {
      time: regl.prop('time'),
      view: regl.prop('view'),
      projection: regl.prop('projection'),
      resolution: regl.prop('resolution'),
      currentPositions: () => framebuffers.current,
      colors: () => framebuffers.colors,
    }

    vert = glsl`
      precision highp float;

      uniform mat4 projection;
      uniform mat4 view;
      uniform sampler2D currentPositions;

      attribute vec2 uv;
      varying vec2 vUv;

      void main() {
        vec4 position = texture2D(currentPositions, uv);

        vUv = uv;

        gl_Position = projection * view * vec4(position.xyz, 1.0);
        gl_PointSize = 2.;
      }
    `

    frag = glsl`
      precision highp float;

      uniform mat4 resolution;
      uniform float time;
      uniform sampler2D colors;

      varying vec2 vUv;

      void main() {
        vec4 color = texture2D(colors, vUv);
        gl_FragColor = color;
        // gl_FragColor = vec4(0.1, 0.3, .7, 1.);
      }
    `
  })

  return {
    drawParticles,
    updateParticles,
  }
}

const Points = props => {
  const ref = useRef(null)

  const [{ width, height }, setSize] = useState({ width: 100, height: 100 })

  useEffect(() => {
    if (!ref.current || width !== 100) {
      return
    }

    const startTime = Date.now()

    const state = {
      amplitude: 0.01,
    }

    anime({
      targets: state,
      amplitude: 1,
      duration: 2000,
      direction: 'alternate',
      loop: true,
      amplitude: [
        { value: 0.01, easing: 'easeInOutElastic(1, .5)' },
        // { value: 0.01, easing: 'easeOutElastic(1, .5)' },
        // { value: 0.95, easing: 'easeOutElastic(1, .5)' },
        { value: 1, easing: 'easeInOutElastic(1, .5)' },
        { value: 0.01, easing: 'easeInOutElastic(1, .5)' },
      ],
    })

    const regl = Regl({
      canvas: ref.current,
      extensions: [
        'OES_texture_float',
        'OES_texture_float_linear',
      ]
    })

    setSize({
      width: 900,
      height: 900,
    })

    const camera  = Camera({
      fov: 50 * Math.PI / 180,
      position: [0, 0, 1],
      near: 0.00001,
      far: 100,
      viewport: [0, 0, width, height]
    })

    const controls = Controls({
      position: camera.position,
      element: ref.current,
      distanceBounds: [1, 100],
      distance: 3,
    })

    const {
      uvs,
      framebuffers,
    } = setupResources(regl, {
      size: 128
    })

    const {
      drawParticles,
      updateParticles,
    } = createShaders(regl, {
      uvs,
      framebuffers,
    })

    regl.frame(() => {
      controls.update()
      controls.copyInto(camera.position, camera.direction, camera.up)

      camera.viewport = [0, 0, width, height]
      camera.update()

      regl.clear({ color: [0, 0, 0, 1] })

      const time = (Date.now() - startTime) / 1000
      const t = Math.abs((((time * 1000) % 5000) / 2500) - 1)
      // const t = .5 * (1 + Math.sin(Math.abs((((time * 1000) % 5000) / 2500) - 1) * Math.PI))

      updateParticles({
        t,
        amplitude: state.amplitude,
      })

      regl.clear({ color: [0, 0, 0, 1] })

      drawParticles({
        time,
        view: camera.view,
        projection: camera.projection,
        resolution: [width, height],
      })

      // cycleParticles()
    })

  }, [ref.current])


  return (
    <Wrapper
      width={ width }
      height={ height }
    >
      <canvas
        ref={ ref }
        width={ width }
        height={ height }
      />
    </Wrapper>
  )
}

export default Points
