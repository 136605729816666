import { Regl } from 'gl'
import { React, styled } from 'x'
import { useRef, useEffect, useState } from 'react'

const Wrapper = styled.div`
  width: ${ props => props.width }px;
  height: ${ props => props.height }px;
`

const setup = canvas => {
  const width = 800
  const height = 800
  const pointWidth = 3
  const sqrtNumParticles = 4
  const numParticles = sqrtNumParticles * sqrtNumParticles

  const regl = Regl({
    canvas,
    extensions: 'OES_texture_float',
  })

  const initialParticleState = new Float32Array(numParticles * 4)
  for (let i = 0; i < numParticles; ++i) {
  	initialParticleState[i * 4] = 2 * Math.random() - 1;
  	initialParticleState[i * 4 + 1] = 2 * Math.random() - 1;
  }


  function createInitialParticleBuffer(initialParticleState) {
  	const initialTexture = regl.texture({
  	  data: initialParticleState,
  	  shape: [sqrtNumParticles, sqrtNumParticles, 4],
  	  type: 'float'
  	})

  	return regl.framebuffer({
  		color: initialTexture,
  		depth: false,
  		stencil: false,
  	})
  }

  let prevParticleState = createInitialParticleBuffer(initialParticleState);
  let currParticleState = createInitialParticleBuffer(initialParticleState);
  let nextParticleState = createInitialParticleBuffer(initialParticleState);

  function cycleParticleStates() {
  	const tmp = prevParticleState;
  	prevParticleState = currParticleState;
  	currParticleState = nextParticleState;
  	nextParticleState = tmp;
  }



  const particleTextureIndex = [];
  for (let i = 0; i < sqrtNumParticles; i++) {
  	for (let j = 0; j < sqrtNumParticles; j++) {
  		particleTextureIndex.push(i / sqrtNumParticles, j / sqrtNumParticles);
  	}
  }


  const updateParticles = regl({

    framebuffer: () => nextParticleState,

    count: 4,

    attributes: {
      position: [
        [ 1, -1],
        [ 1,  1],
        [-1, -1],
        [-1,  1],
      ],
    },

    vert: `
    precision highp float;

    attribute vec2 position;

    varying vec2 uv;

    void main() {
      uv = 0.5 * (1.0 + position);

      gl_Position = vec4(position, 0, 1);
    }
    `,

  	frag: `
      precision mediump float;

    	uniform sampler2D currParticleState;
    	uniform sampler2D prevParticleState;

      varying vec2 uv;

      float rand(vec2 co){
    	  return fract(sin(dot(co.xy, vec2(12.9898,78.233))) * 43758.5453);
    	}

      void main() {
    		vec2 currPosition = texture2D(currParticleState, uv).xy;
    		vec2 prevPosition = texture2D(prevParticleState, uv).xy;

    		vec2 velocity = currPosition - prevPosition;
    		vec2 random = 0.5 - vec2(rand(currPosition), rand(30.0 * currPosition));

    		vec2 position = currPosition + (0.95 * velocity) + (0.0005 * random);


      	gl_FragColor = vec4(position, 0, 1);
      }
  	`,

    uniforms: {
      currParticleState: () => currParticleState,
      prevParticleState: () => prevParticleState,
    },
  })

  const drawParticles = regl({
  	vert: `

    precision mediump float;

  	attribute vec2 particleTextureIndex;
  	uniform sampler2D particleState;


    varying vec3 fragColor;


    uniform float pointWidth;

  	void main() {

  		vec2 position = texture2D(particleState, particleTextureIndex).xy;


  		fragColor = vec3(abs(particleTextureIndex), 1.0);



      gl_Position = vec4(position, 0.0, 1.0);


  		gl_PointSize = pointWidth;
  	}
  	`,

    frag: `
    precision mediump float;

    varying vec3 fragColor;

    void main() {

      gl_FragColor = vec4(fragColor, 1);
    }
    `,

  	attributes: {
  		particleTextureIndex,
  	},

  	uniforms: {
  		particleState: () => currParticleState,
  		pointWidth,
  	},


  	count: numParticles,
  	primitive: 'points',

    depth: {
      enable: false,
      mask: false,
    },
  })


  const frameLoop = regl.frame(({ tick }) => {
  	regl.clear({
  		color: [0, 0, 0, 1],
  		depth: 1,
  	})

  	drawParticles()
  	updateParticles()
  	cycleParticleStates()
  })
}

export default () => {
  const ref = useRef(null)

  const width = 800
  const height = 800

  useEffect(() => {
    if (!ref.current) {
      return
    }

    setup(ref.current)
  })

  return (
    <Wrapper
      width={ width }
      height={ height }
    >
      <canvas
        ref={ ref }
        width={ width }
        height={ height }
      />
    </Wrapper>
  )

}
