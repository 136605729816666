import { glsl } from 'library'
import { React, styled } from 'x'
import { default as Regl } from 'regl'
import { default as resl } from 'resl'
import { useRef, useEffect } from 'react'
import { default as Camera } from 'perspective-camera'
import { default as Controls } from 'orbit-controls'

const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
`

const createDraw = (regl, {
  channelA,
  channelB,
}) => regl(new class {

  vert = glsl`
    precision highp int;
    precision highp float;

    uniform mat4 projection;
    uniform mat4 view;

    attribute vec2 position;

    varying vec2 uv;

    void main() {
      uv = (vec2(1.0) + position) / 2.0;

      // gl_Position = projection * view * vec4(position, 0.0, 1.0);
      gl_Position = vec4(position, 0.0, 1.0);
    }
  `

  frag = glsl`
    precision highp int;
    precision highp float;

    #define TAU 6.2831853071

    uniform float time;
    uniform float width;
    uniform float height;
    uniform sampler2D channelA;
    uniform sampler2D channelB;

    varying vec2 uv;

    void original(out vec4 fragColor, in vec2 fragCoord, in vec2 resolution) {
      vec2 uv = fragCoord.xy / resolution.xy;

      float o = texture2D(channelB, uv * 0.25 + vec2(0.0, time * 0.025)).r;
      float d = (texture2D(channelA, uv * 0.25 - vec2(0.0, time * 0.02 + o * 0.02)).r * 2.0 - 1.0);

      float v = uv.y + d * 0.1;
      v = 1.0 - abs(v * 2.0 - 1.0);
      v = pow(v, 2.0 + sin((time * 0.2 + d * 0.25) * TAU) * 0.5);

      vec3 color = vec3(0.0);

      float x = (1.0 - uv.x * 0.75);
      float y = 1.0 - abs(uv.y * 2.0 - 1.0);
      color += vec3(x * 0.5, y, x) * v;

      vec2 seed = uv.xy;
      vec2 r;
      r.x = fract(sin((seed.x * 12.9898) + (seed.y * 78.2330)) * 43758.5453);
      r.y = fract(sin((seed.x * 53.7842) + (seed.y * 47.5134)) * 43758.5453);

      float s = mix(r.x, (sin((time * 2.5 + 60.0) * r.y) * 0.5 + 0.5) * ((r.y * r.y) * (r.y * r.y)), 0.04);
      color += pow(s, 70.0) * (1.0 - v);

      fragColor.rgb = color;
      fragColor.a = 1.0;
    }

    void aurora(out vec4 fragColor, in vec2 uv) {
      // float o = texture2D(vec4(.5, .5, .5, 1.), uv * 0.25 + vec2(0.0, time * 0.025)).r;
      // float d = (texture2D(vec4(.5, .5, .5, 1.), uv * 0.25 - vec2(0.0, time * 0.02 + o * 0.02)).r * 2.0 - 1.0);

      float o = texture2D(channelB, uv * 0.5 + vec2(0.0, time * 0.025)).r;
      float d = (texture2D(channelA, uv * 0.5 - vec2(0.0, time * 0.02 + o * 0.02)).r * 2.0 - 1.0);

      float v = uv.y + d * 0.1;
      v = 1.0 - abs(v * 2.0 - 1.0);
      v = pow(v, 2.0 + sin((time * 0.2 + d * 0.25) * TAU) * 0.5);

      vec3 color = vec3(0.0);

      float x = (1.0 - uv.x * 0.75);
      float y = 1.0 - abs(uv.y * 2.0 - 1.0);
      color += vec3(x * 0.5, y, x) * v;

      vec2 seed = uv.xy;
      vec2 r;
      r.x = fract(sin((seed.x * 12.9898) + (seed.y * 78.2330)) * 43758.5453);
      r.y = fract(sin((seed.x * 53.7842) + (seed.y * 47.5134)) * 43758.5453);

      float s = mix(r.x, (sin((time * 2.5 + 60.0) * r.y) * 0.5 + 0.5) * ((r.y * r.y) * (r.y * r.y)), 0.04);
      color += pow(s, 70.0) * (1.0 - v);

      fragColor.rgb = color;
      fragColor.a = 1.0;
    }

    void main() {
      vec4 fragColor = vec4(0.0);
      vec2 fragCoord = vec2(gl_FragCoord);
      vec2 resolution = vec2(width, height);

      // original(fragColor, uv);
      original(fragColor, fragCoord, resolution);
      // aurora(fragColor, uv);

      gl_FragColor = vec4(fragColor.z, fragColor.x, fragColor.y, 1.0);
    }
  `

  dither = true
  count = 4
  primitive = 'triangle strip'

  attributes = {
    position: [
      [ 1, -1],
      [ 1,  1],
      [-1, -1],
      [-1,  1],
    ],
  }

  uniforms = {
    channelA,
    channelB,
    width: regl.prop('width'),
    height: regl.prop('height'),
    time: regl.prop('time'),
    view: regl.prop('view'),
    projection: regl.prop('projection'),
  }
})


const clear = ({ regl }) => regl.clear({
  color: [0, 0, 0, 1],
  depth: 1,
})

const Demo = props => {
  const ref = useRef(null)

  useEffect(() => {
    if (!ref.current) {
      return
    }

    const regl = Regl({
      container: ref.current,
      extensions: [
        'EXT_texture_filter_anisotropic',
        // 'oes_texture_float',
       // 'oes_texture_half_float_linear',
        // 'oes_texture_float_linear',
      ]
    })

    const camera  = Camera({
      fov: 50 * Math.PI / 180,
      position: [0, 0, 1],
      near: 0.00001,
      far: 100,
    })

    const controls = Controls({
      position: camera.position,
      element: ref.current,
      distanceBounds: [1, 100],
      distance: 1.5,
    })

    resl({
      manifest: {
        channelA: {
          type: 'image',
          src: '/img/channel-a.png',
        },

        channelB: {
          type: 'image',
          src: '/img/channel-b.jpg',
        },
      },

      onError: error => {
        console.log('ERROR', error)
      },

      onDone: ({ channelA, channelB }) => {
        const startTime = Date.now()

        const draw = createDraw(regl, {
          channelA: regl.texture(channelA, {
            flipY: true,
            min: 'mipmap',
            mag: 'linear',
            mipmap: 'nice',
          }),
          channelB: regl.texture(channelB, {
            flipY: true,
            min: 'mipmap',
            mag: 'linear',
            mipmap: 'nice',
          }),
        })

        regl.frame(() => {
          const width = window.innerWidth
          const height = window.innerHeight

          controls.update()
          controls.copyInto(camera.position, camera.direction, camera.up)

          camera.viewport = [0, 0, width, height]
          camera.update()

          regl.clear({color: [0, 0, 0, 1]})

          draw({
            width,
            height,
            time: (Date.now() - startTime) / 1000,
            view: camera.view,
            projection: camera.projection,
          })
        })
      }
    })

  })

  return (
    <Wrapper ref={ ref } />
  )
}

export default Demo
