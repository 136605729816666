import { React, styled } from 'x'

const Wrapper = styled.div`
  width: 800px;
  height: 800px;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
`

const Button = styled.div`
  display: block;
  width: 170px;
  height: 200px;
  color: #fff;
  font-size: 18px;
  padding: 30px;
  transition: all 0.3s ease;
  text-decoration: none;
  position: relative;
  background: #000;

  ::before {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    border: 2px solid #000;
    position: absolute;
    top: -15px;
    left: -15px;
    transition: all 0.3s ease;
  }

  :hover {
    transform: translate(-15px, -15px);
  }

  :hover::before {
    transform: translate(30px, 30px);
    border-color: #ccc;
  }
`

const SlidingDoor = () => {


  return (
    <Wrapper>
      <Button>
        inno
      </Button>
    </Wrapper>
  )
}

export default SlidingDoor
