import { React, styled } from 'x'
import { Aurora } from 'components'
import { Logo } from 'static'
import { Patch } from 'demos'

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  background: #1F1F1F;
  z-index: 7;
  padding-top: 130px;
`

const StyledLogo = styled(Logo)`
  position: absolute;
  width: 107px;
  fill: #fafafa;
  // transform: translateX(57px) translateY(-12px);
`

const StyledLogoA = styled(StyledLogo)`
  position: absolute;
  width: 307px;
  top: 35px;
  left: 77px;
`

const StyledLogoB = styled(StyledLogo)`
  position: absolute;
  width: 707px;
  top: 92px;
  left: 257px;
`

const TagLine = styled.div``

const Overlay = styled.div`
  pointer-events: none;
  box-sizing: border-box;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 777;
  padding: 30px;
  border: 20px solid #131313;
`

const Minimal = () => {
  const width = typeof window !== 'undefined' ? window.innerWidth : 800
  const height = typeof window !== 'undefined' ? window.innerHeight : 600
  return (
    <Wrapper>
      <Overlay>
        <StyledLogo />
        {/* <StyledLogoA />
        <StyledLogoB /> */}
      </Overlay>
      <Patch
        width={ width }
        height={ height }
        background={ [0.12156, 0.12156, 0.12156, 1] }
      />
      {/* <Aurora
        background={
          // [0.12156, 0.12156, 0.12156]
          [0, 0, 0]
        }
      /> */}
    </Wrapper>
  )
}

export default Minimal
